import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import br from './br/translation.json';
import de from './de/translation.json';
import en from './en/translation.json';
import es from './es/translation.json';
import fr from './fr/translation.json';
import it from './it/translation.json';
import jp from './jp/translation.json';
import nl from './nl/translation.json';

import { ConvertedToObjectType } from './types';

const languageDetector = new LanguageDetector();

const availableLocales = ['br', 'de', 'en', 'es', 'fr', 'it', 'jp', 'nl'];

const normaliseLanguage = (lang: string): string | void => {
  if (lang) {
    return lang.toLowerCase().split('-')[0];
  }
};

languageDetector.addDetector({
  name: 'nav',

  lookup(options) {
    console.log('options', options);
    const foundLang = navigator.languages.find(lang => {
      const normLang = normaliseLanguage(lang);
      if (lang && normLang && availableLocales.includes(normLang)) {
        return true;
      }
      return false;
    });

    let lang = 'en';

    if (foundLang) {
      const foundNormalisedLang = normaliseLanguage(foundLang);
      if (foundNormalisedLang) {
        lang = foundNormalisedLang;
      }
    }

    return lang;
  },
});

const translationsJson = {
  br: {
    translation: br,
  },
  de: {
    translation: de,
  },
  es: {
    translation: es,
  },
  it: {
    translation: it,
  },
  jp: {
    translation: jp,
  },
  nl: {
    translation: nl,
  },
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
};

export type TranslationResource = typeof en;
export type LanguageKey = keyof TranslationResource;

export const translations: ConvertedToObjectType<TranslationResource> = {} as any;

/*
 * Converts the static JSON file into an object where keys are identical
 * but values are strings concatenated according to syntax.
 * This is helpful when using the JSON file keys and still have the intellisense support
 * along with type-safety
 */
const convertLanguageJsonToObject = (obj: any, dict: {}, current?: string) => {
  Object.keys(obj).forEach(key => {
    const currentLookupKey = current ? `${current}.${key}` : key;
    if (typeof obj[key] === 'object') {
      dict[key] = {};
      convertLanguageJsonToObject(obj[key], dict[key], currentLookupKey);
    } else {
      dict[key] = currentLookupKey;
    }
  });
};

export const i18n = i18next
  .use(initReactI18next)
  .use(languageDetector)
  .init(
    {
      resources: translationsJson,
      detection: {
        order: ['nav'],
      },
      fallbackLng: 'en',
      debug:
        process.env.NODE_ENV !== 'production' &&
        process.env.NODE_ENV !== 'test',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    },
    () => {
      convertLanguageJsonToObject(en, translations);
    },
  );
