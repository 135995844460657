import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';

import { HomePage } from './containers/HomePage/Loadable';

import '../font-awesome';

export function App() {
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="Cellar - Show Amazon seller's country of origin as you browse!"
        defaultTitle="Cellar - Show Amazon seller's country of origin as you browse!"
      >
        <meta
          name="description"
          content="Cellar - Show Amazon seller's country of origin as you browse!"
        />
      </Helmet>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
      <GlobalStyle />
    </BrowserRouter>
  );
}
